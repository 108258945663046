<template>
  <b-container fluid>

    <Dash
      url="clients-rentability"
    />
  </b-container>
</template>

<script>
import Dash from '../components/Dash.vue';

export default {
    components: {
        Dash,
    },

};
</script>
